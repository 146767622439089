import controller_0 from '@symfony/ux-autocomplete/dist/controller.js';
import 'tom-select/dist/css/tom-select.bootstrap5.css';
import controller_1 from '@symfony/ux-live-component/dist/live_controller.js';
import '@symfony/ux-live-component/dist/live.min.css';
import controller_2 from '@symfony/ux-turbo/dist/turbo_controller.js';

export default {
'symfony--ux-autocomplete--autocomplete': controller_0,
'live': controller_1,
'symfony--ux-turbo--turbo-core': controller_2
};
